import Vue from 'vue'

// 添加
const sysNoticeSave = data => {
  return Vue.$post('sysNotice/save', data)
}

// 修改
const sysNoticeUpdate = data => {
  return Vue.$put('sysNotice/update', data)
}

// 根据ID查询单个
const sysNoticeGet = id => {
  return Vue.$get(`sysNotice/get/${id}`)
}

// 删除单个
const sysNoticeDelete = id => {
  return Vue.$delete(`sysNotice/delete/${id}`)
}

// 批量删除
const sysNoticeDeleteBatch = data => {
  return Vue.$delete('sysNotice/deleteBatch', data)
}

// 分页查询
const sysNoticePage = data => {
  return Vue.$get('sysNotice/page', data)
}

// 通过对象查询列表
const sysNoticeList = data => {
  return Vue.$get('sysNotice/list', data)
}

export {
  sysNoticeSave,
  sysNoticeUpdate,
  sysNoticeGet,
  sysNoticeDelete,
  sysNoticePage,
  sysNoticeList,
  sysNoticeDeleteBatch
}
