<template>
  <div class="com_no_auth-page">
    <global-icon-svg name="global-noauth" />
    <div class="title">{{ title }}</div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '您没有查看的权限'
    }
  }
}
</script>
