import Vue from 'vue'

// 添加
const companyAuditSave = data => {
  return Vue.$post('companyAudit/save', data)
}

// 修改
const companyAuditUpdate = data => {
  return Vue.$put('companyAudit/update', data)
}

// 根据ID查询单个
const companyAuditGet = id => {
  return Vue.$get(`companyAudit/get/${id}`)
}

// 删除单个
const companyAuditDelete = id => {
  return Vue.$delete(`companyAudit/delete/${id}`)
}

// 批量删除
const companyAuditDeleteBatch = data => {
  return Vue.$delete('companyAudit/deleteBatch', data)
}

// 分页查询
const companyAuditPage = data => {
  return Vue.$get('companyAudit/page', data)
}

// 通过对象查询列表
const companyAuditList = data => {
  return Vue.$get('companyAudit/list', data)
}

export {
  companyAuditSave,
  companyAuditUpdate,
  companyAuditGet,
  companyAuditDelete,
  companyAuditPage,
  companyAuditList,
  companyAuditDeleteBatch
}
