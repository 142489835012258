/* eslint-disable */

import bizagreementMethodRouter from '@/views/biz/agreementMethod/router.js'
import bizagreementRuleRouter from '@/views/biz/agreementRule/router.js'
import bizagreementTemplateRouter from '@/views/biz/agreementTemplate/router.js'
import bizcompanyRouter from '@/views/biz/company/router.js'
import bizcompanyAuditRouter from '@/views/biz/companyAudit/router.js'
import bizcompanyInfoRouter from '@/views/biz/companyInfo/router.js'
import bizlawyerRouter from '@/views/biz/lawyer/router.js'
import bizlawyerInfoRouter from '@/views/biz/lawyerInfo/router.js'
import bizlayFirmRouter from '@/views/biz/layFirm/router.js'
import bizlayFirmInfoRouter from '@/views/biz/layFirmInfo/router.js'
import bizmediateRouter from '@/views/biz/mediate/router.js'
import bizmediateAuditRouter from '@/views/biz/mediateAudit/router.js'
import bizmediateTemplateRouter from '@/views/biz/mediateTemplate/router.js'
import bizpersonRouter from '@/views/biz/person/router.js'
import bizpersonInfoRouter from '@/views/biz/personInfo/router.js'
import bizproductRouter from '@/views/biz/product/router.js'
import bizproductCategoryRouter from '@/views/biz/productCategory/router.js'
import bizrepaymentAgreementRouter from '@/views/biz/repaymentAgreement/router.js'
import bizrepaymentAgreementOrderRouter from '@/views/biz/repaymentAgreementOrder/router.js'
import bizrepaymentMethodRouter from '@/views/biz/repaymentMethod/router.js'
import bizruleRouter from '@/views/biz/rule/router.js'
import bizruleItemRouter from '@/views/biz/ruleItem/router.js'
import bizruleProductRouter from '@/views/biz/ruleProduct/router.js'
import biztargetCustomerRouter from '@/views/biz/targetCustomer/router.js'
import biztemplateRouter from '@/views/biz/template/router.js'
import bizuserAccRouter from '@/views/biz/userAcc/router.js'
import sysareaRouter from '@/views/sys/area/router.js'
import sysdepartmentRouter from '@/views/sys/department/router.js'
import sysdictRouter from '@/views/sys/dict/router.js'
import sysfileRouter from '@/views/sys/file/router.js'
import sysloginLogRouter from '@/views/sys/loginLog/router.js'
import sysparamRouter from '@/views/sys/param/router.js'
import sysresourceRouter from '@/views/sys/resource/router.js'
import sysroleRouter from '@/views/sys/role/router.js'
import sysrulesRouter from '@/views/sys/rules/router.js'
import syssitesetRouter from '@/views/sys/siteset/router.js'
import syssysNoticeRouter from '@/views/sys/sysNotice/router.js'
import sysuserRouter from '@/views/sys/user/router.js'
import systemfunctionRouter from '@/views/system/function/router.js'
import systemindexRouter from '@/views/system/index/router.js'
import systemlogRouter from '@/views/system/log/router.js'
import systemloginRouter from '@/views/system/login/router.js'
import systemmyRouter from '@/views/system/my/router.js'
import systemregisterRouter from '@/views/system/register/router.js' 

export default [
bizagreementMethodRouter, 
bizagreementRuleRouter, 
bizagreementTemplateRouter, 
bizcompanyRouter, 
bizcompanyAuditRouter, 
bizcompanyInfoRouter, 
bizlawyerRouter, 
bizlawyerInfoRouter, 
bizlayFirmRouter, 
bizlayFirmInfoRouter, 
bizmediateRouter, 
bizmediateAuditRouter, 
bizmediateTemplateRouter, 
bizpersonRouter, 
bizpersonInfoRouter, 
bizproductRouter, 
bizproductCategoryRouter, 
bizrepaymentAgreementRouter, 
bizrepaymentAgreementOrderRouter, 
bizrepaymentMethodRouter, 
bizruleRouter, 
bizruleItemRouter, 
bizruleProductRouter, 
biztargetCustomerRouter, 
biztemplateRouter, 
bizuserAccRouter, 
sysareaRouter, 
sysdepartmentRouter, 
sysdictRouter, 
sysfileRouter, 
sysloginLogRouter, 
sysparamRouter, 
sysresourceRouter, 
sysroleRouter, 
sysrulesRouter, 
syssitesetRouter, 
syssysNoticeRouter, 
sysuserRouter, 
systemfunctionRouter, 
systemindexRouter, 
systemlogRouter, 
systemloginRouter, 
systemmyRouter, 
systemregisterRouter
] 

