import Vue from 'vue'

// 添加
const layFirmSave = data => {
  return Vue.$post('layFirm/save', data)
}

// 修改
const layFirmUpdate = data => {
  return Vue.$put('layFirm/update', data)
}

// 根据ID查询单个
const layFirmGet = id => {
  return Vue.$get(`layFirm/get/${id}`)
}

// 删除单个
const layFirmDelete = id => {
  return Vue.$delete(`layFirm/delete/${id}`)
}

// 批量删除
const layFirmDeleteBatch = data => {
  return Vue.$delete('layFirm/deleteBatch', data)
}

// 分页查询
const layFirmPage = data => {
  return Vue.$get('layFirm/page', data)
}

// 通过对象查询列表
const layFirmList = data => {
  return Vue.$get('layFirm/list', data)
}

export { layFirmSave, layFirmUpdate, layFirmGet, layFirmDelete, layFirmPage, layFirmList, layFirmDeleteBatch }
