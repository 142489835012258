import districts from './districts'
export default {
  name: 'global-address',
  props: {
    source: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      address: []
    }
  },
  computed: {
    options() {
      return districts
    }
  },

  watch: {
    source(val) {
      this.address = val
    }
  },

  methods: {
    changeAddress() {
      this.$emit('getAddress', this.address)
    }
  }
}
