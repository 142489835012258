import _ from 'lodash'

export default {
  name: 'global-pager',

  props: {
    total: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      currentPage: 1,
      limit: 15
    }
  },

  computed: {
    query() {
      return this.$route.query
    },

    page() {
      return Number(_.get(this.$route, 'query.page', 1))
    },

    pageSize() {
      return Number(_.get(this.$route, 'query.limit', 15))
    }
  },

  mounted() {
    this.init()
  },

  watch: {
    query() {
      this.init()
    }
  },

  methods: {
    init() {
      this.currentPage = this.page
      this.limit = this.pageSize
    },

    handleSizeChange(val) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.query,
          limit: Number(val),
          page: 1
        }
      })
    },

    handleCurrentChange(val) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.query,
          page: Number(val)
        }
      })
    }
  }
}
