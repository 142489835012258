var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-body-wrap" },
    [
      _vm.code
        ? [
            _c(
              "global-container",
              [
                _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
                _vm.$checkAuth(_vm.code)
                  ? [_vm._t("default")]
                  : [_c("global-noauth")],
              ],
              2
            ),
          ]
        : [_vm._t("default")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }