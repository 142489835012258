import _ from 'lodash'
// 设置文件
import setting from '@/setting.js'
// import menuAside from '@/menu/aside'
import { Message, MessageBox } from 'element-ui'
import { resourceSave, resourceUpdate, resourceDelete, resourceListTree } from '@api'

/**
 * 给菜单数据补充上 path 字段
 * https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */
function supplementMenuPath(menu) {
  return menu.map(e => ({
    ..._.pick(e, ['id', 'isExtend', 'resourceCode']),
    icon: e.icon ? e.icon : undefined,
    path: e.url || e.path || _.uniqueId('global-menu-empty-'),
    title: e.resourceName || e.title,
    ...(_.get(e, 'children.length')
      ? {
          children: supplementMenuPath(e.children)
        }
      : {})
  }))
}

export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    resource: [],
    // 树状默认展开
    expandedIds: [],
    loading: false,
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse
  },
  actions: {
    // 获取菜单资源
    fetchMenuList({ state, dispatch }, { pid = 0 } = {}) {
      return new Promise((resolve, reject) => {
        state.loading = true
        resourceListTree({
          pid
        })
          .then(async res => {
            state.resource = res
            if (!state.expandedIds.length) {
              state.expandedIds = [_.get(res, '[0].id', '')]
            }
            state.loading = false
            resolve()
          })
          .catch(err => {
            state.loading = false
            console.log('err: ', err)
            reject(err)
          })
      })
    },

    // 添加菜单资源
    addMenu({ state, dispatch }, data = {}) {
      return new Promise((resolve, reject) => {
        resourceSave(data)
          .then(async res => {
            await dispatch('fetchMenuList')
            state.expandedIds = [_.get(res, 'id', '')]
            Message.success('资源新增成功')
            resolve()
          })
          .catch(err => {
            console.log('err: ', err)
            reject(err)
          })
      })
    },

    // 修改菜单资源
    updateMenu({ state, dispatch }, data = {}) {
      return new Promise((resolve, reject) => {
        resourceUpdate(data)
          .then(async res => {
            await dispatch('fetchMenuList')
            state.expandedIds = [_.get(res, 'id', '')]
            Message.success('资源更新成功')
            resolve()
          })
          .catch(err => {
            console.log('err: ', err)
            reject(err)
          })
      })
    },

    // 删除菜单资源
    deleteMenu({ state, dispatch }, data = {}, { confirm = true } = {}) {
      const onDelete = () => {
        resourceDelete(data.id)
          .then(async res => {
            await dispatch('fetchMenuList')
            state.expandedIds = [_.get(data, 'pid', '')]
            Message.success(res)
          })
          .catch(err => {
            console.log('err: ', err)
          })
      }

      if (confirm) {
        MessageBox.confirm('您确认要删除当前资源?', '删除资源', {
          type: 'warning'
        })
          .then(() => {
            onDelete()
          })
          .catch(() => {})
      } else {
        onDelete()
      }
    },
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} context
     * @param {Boolean} collapse is collapse
     */
    asideCollapseSet({ state, dispatch }, collapse) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = collapse
        // 持久化
        await dispatch(
          'db/set',
          {
            dbName: 'sys',
            path: 'menu.asideCollapse',
            value: state.asideCollapse,
            user: true
          },
          { root: true }
        )
        // end
        resolve()
      })
    },
    /**
     * 切换侧边栏展开和收缩
     * @param {Object} context
     */
    asideCollapseToggle({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = !state.asideCollapse
        // 持久化
        await dispatch(
          'db/set',
          {
            dbName: 'sys',
            path: 'menu.asideCollapse',
            value: state.asideCollapse,
            user: true
          },
          { root: true }
        )
        // end
        resolve()
      })
    },
    /**
     * 从持久化数据读取侧边栏展开或者收缩
     * @param {Object} context
     */
    asideCollapseLoad({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.asideCollapse = await dispatch(
          'db/get',
          {
            dbName: 'sys',
            path: 'menu.asideCollapse',
            defaultValue: setting.menu.asideCollapse,
            user: true
          },
          { root: true }
        )
        // end
        resolve()
      })
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSet({ state, rootState }) {
      return new Promise(resolve => {
        // store 赋值
        const home = [{ path: '/index', title: '首页', icon: 'home' }]
        const accountType = _.get(rootState, 'user.info.accountType', '')
        const aside = _.concat(home, rootState.account.menu || [])
        const formatAside =
          accountType === 'manager'
            ? _.map(aside, item => {
                if (_.has(item, 'children')) {
                  return {
                    ..._.omit(item, 'children'),
                    children: _.filter(item.children, elem => !['agentInfo', 'entInfo'].includes(elem.resourceCode))
                  }
                } else {
                  return item
                }
              })
            : aside
        state.aside = supplementMenuPath(formatAside)
        // state.aside = supplementMenuPath(menuAside)
        // end
        resolve()
      })
    }
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    headerSet(state, menu) {
      // store 赋值
      state.header = supplementMenuPath(menu)
    }
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    // asideSet({ state, rootState }, menu = []) {
    //   return new Promise(async resolve => {
    //     // store 赋值
    //     const menu = await dispatch('getMenuList')
    //     state.aside = supplementMenuPath(menu)
    //     // end
    //     resolve()
    //   })
    // }
  }
}
