/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: 'ruleProduct',
    name: 'ruleProduct',
    meta: {
      title: 'ruleProduct管理',
      code: 'ruleProduct',
      auth: true
    },
    component: () => import('./index.vue')
  }
]
/**
 * 在主框架之外显示
 */
const frameOut = []

// 重新组织后导出
export default {
  frameIn,
  frameOut
}
