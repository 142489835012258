var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "upload_wrap",
      class: {
        "cus-class": _vm.className,
        "file-wrap": _vm.uploadType === "file",
        "list-upload-wrap": _vm.isList,
      },
      style: "width:" + _vm.width + ";height:" + _vm.height + ";",
    },
    [
      !_vm.isList
        ? [
            !_vm.fileUrl || _vm.uploadType !== "file"
              ? [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      class: _vm.className,
                      attrs: {
                        disabled: _vm.disabled,
                        action: "/lawtorrentapi/upload",
                        headers: _vm.uploadHeader,
                        "show-file-list": false,
                        "on-success": _vm.handleSuccess,
                        "on-preview": _vm.handlePictureCardPreview,
                        "before-upload": _vm.beforeUpload,
                      },
                    },
                    [
                      _vm.uploadType === "file"
                        ? [
                            _c("el-button", { attrs: { type: "default" } }, [
                              _vm._v("上传附件"),
                            ]),
                          ]
                        : [
                            _vm.fileUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  style:
                                    "width:" +
                                    _vm.width +
                                    ";height:" +
                                    _vm.height +
                                    ";",
                                  attrs: { src: _vm.fileUrl },
                                })
                              : [
                                  _vm.className
                                    ? [
                                        _c("global-icon-svg", {
                                          staticClass: "add-icon",
                                          attrs: { name: "global-add" },
                                        }),
                                        _c("global-icon-svg", {
                                          attrs: { name: _vm.iconName },
                                        }),
                                      ]
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ],
                          ],
                    ],
                    2
                  ),
                  _vm.fileUrl && !_vm.disabled
                    ? _c(
                        "span",
                        {
                          staticClass: "close",
                          on: { click: _vm.removeImage },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    : _vm._e(),
                ]
              : _c("div", [
                  _c("a", { attrs: { href: _vm.fileUrl, target: "_blank" } }, [
                    _vm._v("查看附件"),
                  ]),
                  _vm.fileUrl && !_vm.disabled
                    ? _c(
                        "span",
                        {
                          staticClass: "close-file",
                          on: { click: _vm.removeImage },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      )
                    : _vm._e(),
                ]),
          ]
        : _c(
            "div",
            { staticClass: "list_upload" },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "/lawtorrentapi/upload",
                    headers: _vm.uploadHeader,
                    "list-type": "picture-card",
                    "file-list": _vm.fileScurceList,
                    disabled: _vm.disabled,
                    "on-success": _vm.listSuccess,
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { "append-to-body": true, visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogfileUrl, alt: "" },
                  }),
                ]
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }