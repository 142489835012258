import Vue from 'vue'

// 添加
const ruleSave = data => {
  return Vue.$post('rule/save', data)
}

// 修改
const ruleUpdate = data => {
  return Vue.$put('rule/update', data)
}

// 根据ID查询单个
const ruleGet = id => {
  return Vue.$get(`rule/get/${id}`)
}

// 删除单个
const ruleDelete = id => {
  return Vue.$delete(`rule/delete/${id}`)
}

// 批量删除
const ruleDeleteBatch = data => {
  return Vue.$delete('rule/deleteBatch', data)
}

// 分页查询
const rulePage = data => {
  return Vue.$get('rule/page', data)
}

// 通过对象查询列表
const ruleList = data => {
  return Vue.$get('rule/list', data)
}

// 禁用启用
const ruleUpdateEnable = data => {
  return Vue.$put('rule/updateEnable', data)
}

export { ruleSave, ruleUpdate, ruleGet, ruleDelete, rulePage, ruleList, ruleDeleteBatch, ruleUpdateEnable }
