import Vue from 'vue'

// 菜单资源添加
const resourceSave = data => {
  return Vue.$post('sysResource/save', data)
}

// 菜单资源修改
const resourceUpdate = data => {
  return Vue.$put('sysResource/update', data)
}

// 根据ID查询单个
const resourceGet = id => {
  return Vue.$get(`sysResource/get/${id}`)
}

// 删除单个
const resourceDelete = id => {
  return Vue.$delete(`sysResource/delete/${id}`)
}

// 分页查询
// const resourcePage = data => {
//   return Vue.$get('sysResource/page', data)
// }

// 通过对象查询列表
const resourceListTree = data => {
  return Vue.$get('sysResource/listTree', data)
}

// 验证资源code是否唯一
const checkCode = resourceCode => {
  return Vue.$get('sysResource/checkName', { resourceCode })
}

export { resourceSave, resourceUpdate, resourceGet, resourceDelete, resourceListTree, checkCode }
