import Vue from 'vue'

// 获取系统设置基本信息
const getSkin = data => {
  return Vue.$get('getSkin', data)
}

// 设置系统设置基本信息
const setSkin = data => {
  return Vue.$put('setSkin', data)
}

export { getSkin, setSkin }
