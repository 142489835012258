import _ from 'lodash'
import util from '@/libs/util.js'

export default {
  methods: {
    handleMenuSelect(index) {
      const obj = JSON.parse(index)
      const path = _.get(obj, 'path', '')
      const link = _.get(obj, 'link', 'F')
      if (/^global-menu-empty-\d+$/.test(path) || !path) {
        this.$message.warning('临时菜单')
      } else if (/^https:\/\/|http:\/\//.test(path) && link === 'T') {
        util.open(path)
      } else {
        this.$router.push({
          path: path
        })
      }
    }
  }
}
