var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-select-list" },
    [
      _vm.formType === "select"
        ? [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.label + "：",
                  prop: "" + _vm.prop,
                  required: _vm.required,
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择" + _vm.label,
                      multiple: _vm.multiple,
                      clearable: "",
                      disabled: _vm.disabled,
                      filterable: _vm.filterable,
                      "allow-create": _vm.allowCreate,
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.formType === "radio"
        ? [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.label + "：",
                  prop: "" + _vm.prop,
                  required: _vm.required,
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.disabled },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c(
                      "el-radio",
                      { key: item.id, attrs: { label: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.formType === "checkbox"
        ? [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.label + "：",
                  prop: "" + _vm.prop,
                  required: _vm.required,
                },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    attrs: { disabled: _vm.disabled },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  _vm._l(_vm.options, function (item) {
                    return _c(
                      "el-checkbox",
                      { key: item.id, attrs: { label: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }