<template>
  <div class="main-body-wrap">
    <template v-if="code">
      <global-container>
        <template slot="header">{{ title }}</template>
        <template v-if="$checkAuth(code)">
          <slot></slot>
        </template>
        <template v-else>
          <global-noauth></global-noauth>
        </template>
      </global-container>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  computed: {
    code() {
      return _.get(this.$route, 'meta.code', '')
    },

    title() {
      return _.get(this.$route, 'meta.title', '')
    }
  }
}
</script>
