import Vue from 'vue'

// 添加
const sysRulesSave = data => {
  return Vue.$post('sysRules/save', data)
}

// 修改
const sysRulesUpdate = data => {
  return Vue.$put('sysRules/update', data)
}

// 根据ID查询单个
const sysRulesGet = id => {
  return Vue.$get(`sysRules/get/${id}`)
}

// 删除单个
const sysRulesDelete = id => {
  return Vue.$delete(`sysRules/delete/${id}`)
}

// 批量删除
const sysRulesDeleteBatch = data => {
  return Vue.$delete('sysRules/deleteBatch', data)
}

// 分页查询
const sysRulesPage = data => {
  return Vue.$get('sysRules/page', data)
}

// 通过对象查询列表
const sysRulesList = data => {
  return Vue.$get('sysRules/list', data)
}

export { sysRulesSave, sysRulesUpdate, sysRulesGet, sysRulesDelete, sysRulesPage, sysRulesList, sysRulesDeleteBatch }
