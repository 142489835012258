import Vue from 'vue'

// 添加
const ruleItemSave = data => {
  return Vue.$post('ruleItem/save', data)
}

// 修改
const ruleItemUpdate = data => {
  return Vue.$put('ruleItem/update', data)
}

// 根据ID查询单个
const ruleItemGet = id => {
  return Vue.$get(`ruleItem/get/${id}`)
}

// 删除单个
const ruleItemDelete = id => {
  return Vue.$delete(`ruleItem/delete/${id}`)
}

// 批量删除
const ruleItemDeleteBatch = data => {
  return Vue.$delete('ruleItem/deleteBatch', data)
}

// 分页查询
const ruleItemPage = data => {
  return Vue.$get('ruleItem/page', data)
}

// 通过对象查询列表
const ruleItemList = data => {
  return Vue.$get('ruleItem/list', data)
}

export { ruleItemSave, ruleItemUpdate, ruleItemGet, ruleItemDelete, ruleItemPage, ruleItemList, ruleItemDeleteBatch }
