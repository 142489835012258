import Vue from 'vue'

// 添加
const sysLoginLogSave = data => {
  return Vue.$post('sysLoginLog/save', data)
}

// 修改
const sysLoginLogUpdate = data => {
  return Vue.$put('sysLoginLog/update', data)
}

// 根据ID查询单个
const sysLoginLogGet = id => {
  return Vue.$get(`sysLoginLog/get/${id}`)
}

// 删除单个
const sysLoginLogDelete = id => {
  return Vue.$delete(`sysLoginLog/delete/${id}`)
}

// 批量删除
const sysLoginLogDeleteBatch = data => {
  return Vue.$delete('sysLoginLog/deleteBatch', data)
}

// 分页查询
const sysLoginLogPage = data => {
  return Vue.$get('sysLoginLog/page', data)
}

// 通过对象查询列表
const sysLoginLogList = data => {
  return Vue.$get('sysLoginLog/list', data)
}

export {
  sysLoginLogSave,
  sysLoginLogUpdate,
  sysLoginLogGet,
  sysLoginLogDelete,
  sysLoginLogPage,
  sysLoginLogList,
  sysLoginLogDeleteBatch
}
