import _ from 'lodash'
import * as math from 'mathjs'

export default {
  install(Vue, options) {
    Vue.$formatMoney = Vue.prototype.$formatMoney = (price, length = 2) => {
      if (price === '/') {
        return '0.00'
      }
      let formatPrice = parseFloat(parseFloat((price * 10000) / 10000).toFixed(length))
      let str = `${formatPrice}`
      let dotLength = parseInt(_.get(_.split(str, '.'), '[1].length', 0))
      return dotLength <= 2 ? formatPrice.toFixed(2) : formatPrice
    }

    Vue.filter('formatMoney', function(val) {
      return parseFloat(val).toFixed(2)
    })

    Vue.$formatNumber = Vue.prototype.$formatNumber = (value, precision = 14) => {
      return Number(math.format(value, precision))
    }
  }
}
