import Vue from 'vue'

// 用户登录
const AccountLogin = data => {
  return Vue.$post('login', data)
}

// 退出登录
const AccountLogout = () => {
  return Vue.$post('logout')
}

// 获取登录信息
const AccountLoginInfo = userId => {
  return Vue.$post('loginInfo', { userId })
}

// 获取验证码
const getValidateCode = () => {
  return Vue.$get('validateCode')
}

export { AccountLogin, AccountLogout, AccountLoginInfo, getValidateCode }
