import Vue from 'vue'
import { getSkin } from '@api'

export default {
  namespaced: true,
  state: {
    // 站点配置信息
    siteInfo: {}
  },
  actions: {
    /**
     * @description 获取站点配置信息
     * @param {Object} context
     */
    getSkinInfo({ state }) {
      return new Promise((resolve, reject) => {
        getSkin()
          .then(data => {
            Vue.set(state, 'siteInfo', data || {})
            resolve()
          })
          .catch(err => {
            console.log('err: ', err)
            reject(err)
          })
      })
    }
  }
}
