import Vue from 'vue'

// 部门添加
const deptSave = data => {
  return Vue.$post('sysDept/save', data)
}

// 部门修改
const deptUpdate = data => {
  return Vue.$put('sysDept/update', data)
}

// 根据ID查询单个
const deptGet = id => {
  return Vue.$get(`sysDept/get/${id}`)
}

// 删除单个
const deptDelete = id => {
  return Vue.$delete(`sysDept/delete/${id}`)
}

// 批量删除
const deptDeleteBatch = data => {
  return Vue.$delete('sysDept/deleteBatch', data)
}

// 分页查询
const deptPage = data => {
  return Vue.$get('sysDept/page', data)
}

// 通过对象查询列表
const deptList = data => {
  return Vue.$get('sysDept/list', data)
}

// 通过对象查询列表
const deptListTree = data => {
  return Vue.$get('sysDept/listTree', data)
}

// 通过对象查询列表
const deptListTreeForSelect = data => {
  return Vue.$get('sysDept/listTreeForSelect', data)
}

export {
  deptListTreeForSelect,
  deptListTree,
  deptSave,
  deptUpdate,
  deptGet,
  deptDelete,
  deptPage,
  deptList,
  deptDeleteBatch
}
