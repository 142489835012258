import Vue from 'vue'

// 添加
const ruleProductSave = data => {
  return Vue.$post('ruleProduct/save', data)
}

// 修改
const ruleProductUpdate = data => {
  return Vue.$put('ruleProduct/update', data)
}

// 根据ID查询单个
const ruleProductGet = id => {
  return Vue.$get(`ruleProduct/get/${id}`)
}

// 删除单个
const ruleProductDelete = id => {
  return Vue.$delete(`ruleProduct/delete/${id}`)
}

// 批量删除
const ruleProductDeleteBatch = data => {
  return Vue.$delete('ruleProduct/deleteBatch', data)
}

// 分页查询
const ruleProductPage = data => {
  return Vue.$get('ruleProduct/page', data)
}

// 通过对象查询列表
const ruleProductList = data => {
  return Vue.$get('ruleProduct/list', data)
}

export {
  ruleProductSave,
  ruleProductUpdate,
  ruleProductGet,
  ruleProductDelete,
  ruleProductPage,
  ruleProductList,
  ruleProductDeleteBatch
}
