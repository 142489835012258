import Vue from 'vue'

// 添加
const mediateAuditSave = data => {
  return Vue.$post('mediateAudit/save', data)
}

// 修改
const mediateAuditUpdate = data => {
  return Vue.$put('mediateAudit/update', data)
}

// 根据ID查询单个
const mediateAuditGet = id => {
  return Vue.$get(`mediateAudit/get/${id}`)
}

// 删除单个
const mediateAuditDelete = id => {
  return Vue.$delete(`mediateAudit/delete/${id}`)
}

// 批量删除
const mediateAuditDeleteBatch = data => {
  return Vue.$delete('mediateAudit/deleteBatch', data)
}

// 分页查询
const mediateAuditPage = data => {
  return Vue.$get('mediateAudit/page', data)
}

// 通过对象查询列表
const mediateAuditList = data => {
  return Vue.$get('mediateAudit/list', data)
}

export {
  mediateAuditSave,
  mediateAuditUpdate,
  mediateAuditGet,
  mediateAuditDelete,
  mediateAuditPage,
  mediateAuditList,
  mediateAuditDeleteBatch
}
