var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "global-source",
          class: { "global-source--active": _vm.isActive },
          on: { click: _vm.handleClick },
        },
        [_c("global-icon", { attrs: { name: "code" } }), _vm._v(" 本页源码 ")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }