import Vue from 'vue'

// 添加
const agreementMethodSave = data => {
  return Vue.$post('agreementMethod/save', data)
}

// 修改
const agreementMethodUpdate = data => {
  return Vue.$put('agreementMethod/update', data)
}

// 根据ID查询单个
const agreementMethodGet = id => {
  return Vue.$get(`agreementMethod/get/${id}`)
}

// 删除单个
const agreementMethodDelete = id => {
  return Vue.$delete(`agreementMethod/delete/${id}`)
}

// 批量删除
const agreementMethodDeleteBatch = data => {
  return Vue.$delete('agreementMethod/deleteBatch', data)
}

// 分页查询
const agreementMethodPage = data => {
  return Vue.$get('agreementMethod/page', data)
}

// 通过对象查询列表
const agreementMethodList = data => {
  return Vue.$get('agreementMethod/list', data)
}

export {
  agreementMethodSave,
  agreementMethodUpdate,
  agreementMethodGet,
  agreementMethodDelete,
  agreementMethodPage,
  agreementMethodList,
  agreementMethodDeleteBatch
}
