import Vue from 'vue'

// 添加
const personInfoSave = data => {
  return Vue.$post('personInfo/save', data)
}

// 修改
const personInfoUpdate = data => {
  return Vue.$put('personInfo/update', data)
}

// 根据ID查询单个
const personInfoGet = id => {
  return Vue.$get(`personInfo/get/${id}`)
}

// 删除单个
const personInfoDelete = id => {
  return Vue.$delete(`personInfo/delete/${id}`)
}

// 批量删除
const personInfoDeleteBatch = data => {
  return Vue.$delete('personInfo/deleteBatch', data)
}

// 分页查询
const personInfoPage = data => {
  return Vue.$get('personInfo/page', data)
}

// 通过对象查询列表
const personInfoList = data => {
  return Vue.$get('personInfo/list', data)
}

export {
  personInfoSave,
  personInfoUpdate,
  personInfoGet,
  personInfoDelete,
  personInfoPage,
  personInfoList,
  personInfoDeleteBatch
}
