import Vue from 'vue'

// 用户添加
const userSave = data => {
  return Vue.$post('sysUser/save', data)
}

// 用户修改
const userUpdate = data => {
  return Vue.$put('sysUser/update', data)
}

// 根据ID查询单个
const userGet = id => {
  return Vue.$get(`sysUser/get/${id}`)
}

// 删除单个
const userDelete = id => {
  return Vue.$delete(`sysUser/delete/${id}`)
}

// 分页查询
const userPage = data => {
  return Vue.$get('sysUser/page', data)
}

// 启用用户
const userEnable = id => {
  return Vue.$put(`sysUser/enable/${id}`)
}

// 禁用用户
const userDisable = id => {
  return Vue.$put(`sysUser/disable/${id}`)
}

// 用户选择角色列表
const userPageForRoleSelect = data => {
  return Vue.$get('sysUser/pageForRoleSelect', data)
}

// 用户选择角色-保存
const userRoleSave = data => {
  return Vue.$post('sysUserRole/save', data)
}

// 用户选择角色-取消
const userRoleDelete = id => {
  return Vue.$delete(`sysUserRole/delete/${id}`)
}

// 批量删除
const userDeleteBatch = data => {
  return Vue.$delete('sysUser/deleteBatch', data)
}

// 验证用户名是否重复(新增)
const checkName = loginName => {
  return Vue.$get('sysUser/checkName', { loginName })
}
// 验证用户名是否重复(编辑)
const userCheckNameForUpdate = data => {
  return Vue.$get('sysUser/checkNameForUpdate', data)
}

export {
  checkName,
  userCheckNameForUpdate,
  userSave,
  userUpdate,
  userGet,
  userDelete,
  userDeleteBatch,
  userPage,
  userEnable,
  userDisable,
  userRoleSave,
  userRoleDelete,
  userPageForRoleSelect
}
