import Vue from 'vue'

// 添加
const targetCustomerSave = data => {
  return Vue.$post('targetCustomer/save', data)
}

// 修改
const targetCustomerUpdate = data => {
  return Vue.$put('targetCustomer/update', data)
}

// 根据ID查询单个
const targetCustomerGet = id => {
  return Vue.$get(`targetCustomer/get/${id}`)
}

// 删除单个
const targetCustomerDelete = id => {
  return Vue.$delete(`targetCustomer/delete/${id}`)
}

// 批量删除
const targetCustomerDeleteBatch = data => {
  return Vue.$delete('targetCustomer/deleteBatch', data)
}

// 分页查询
const targetCustomerPage = data => {
  return Vue.$get('targetCustomer/page', data)
}

// 通过对象查询列表
const targetCustomerList = data => {
  return Vue.$get('targetCustomer/list', data)
}

export {
  targetCustomerSave,
  targetCustomerUpdate,
  targetCustomerGet,
  targetCustomerDelete,
  targetCustomerPage,
  targetCustomerList,
  targetCustomerDeleteBatch
}
