import Vue from 'vue'

// 添加
const sysFileSave = data => {
  return Vue.$post('sysFile/save', data)
}

// 修改
const sysFileUpdate = data => {
  return Vue.$put('sysFile/update', data)
}

// 根据ID查询单个
const sysFileGet = id => {
  return Vue.$get(`sysFile/get/${id}`)
}

// 删除单个
const sysFileDelete = id => {
  return Vue.$delete(`sysFile/delete/${id}`)
}

// 批量删除
const sysFileDeleteBatch = data => {
  return Vue.$delete('sysFile/deleteBatch', data)
}

// 分页查询
const sysFilePage = data => {
  return Vue.$get('sysFile/page', data)
}

// 通过对象查询列表
const sysFileList = data => {
  return Vue.$get('sysFile/list', data)
}

export { sysFileSave, sysFileUpdate, sysFileGet, sysFileDelete, sysFilePage, sysFileList, sysFileDeleteBatch }
