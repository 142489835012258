<template>
  <div class="quill-editor">
    <!-- 图片上传组件辅助-->
    <el-upload
      id="quill-upload"
      class="quill-upload"
      :action="serverUrl"
      :headers="header"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :before-upload="beforeUpload"
    >
    </el-upload>
    <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)">
    </quill-editor>
  </div>
</template>

<script>
import Vue from 'vue'
import util from '@/libs/util'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ['link', 'image'],
  ['clean'],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }]
  // [{ font: [] }],
  // [{ 'script': 'sub' }, { 'script': 'super' }],
  // [{ 'indent': '-1' }, { 'indent': '+1' }],
  // [{ 'direction': 'rtl' }],
]

export default {
  props: {
    value: {
      type: String
    }
  },

  data() {
    return {
      quillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      content: null,
      editorOption: {
        placeholder: '',
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function(value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector('#quill-upload input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      },
      serverUrl: '/api/upload', // 这里写你要上传的图片服务器地址
      header: {
        Token: util.cookies.get('token')
      } // 有的图片服务器要求请求头需要有token
    }
  },

  mounted() {
    this.content = this.value
  },

  watch: {
    value() {
      this.content = this.value
    }
  },

  methods: {
    onEditorChange({ editor, html, text }) {
      this.content = html
      this.$emit('change', html)
    },

    // 富文本图片上传前
    beforeUpload() {
      // 显示loading动画
      this.quillUpdateImg = true
    },

    uploadSuccess(res, file) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill
      // 如果上传成功
      if (res.code === 200) {
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片 res.url为服务器返回的图片地址
        quill.insertEmbed(length, 'image', res.data.fileUrl)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.quillUpdateImg = false
    },

    // 富文本图片上传失败
    uploadError() {
      // loading动画消失
      this.quillUpdateImg = false
      this.$message.error('图片插入失败')
    }
  }
}
</script>
<style lang="scss" scoped>
.quill-editor {
  .quill-upload {
    height: 0 !important;
    overflow: hidden;
    line-height: 0 !important;
  }
}
</style>
