import _ from 'lodash'
import layoutHeaderAside from '@/layout'
import routerList from './_import.js'

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: _.flatMap(routerList, 'frameIn')
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = _.flatMap(routerList, 'frameOut')

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: () => import('@/views/system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [...frameIn, ...frameOut, ...errorPage]
