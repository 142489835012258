import Vue from 'vue'

// 添加
const templateSave = data => {
  return Vue.$post('template/save', data)
}

// 修改
const templateUpdate = data => {
  return Vue.$put('template/update', data)
}

// 根据ID查询单个
const templateGet = id => {
  return Vue.$get(`template/get/${id}`)
}

// 删除单个
const templateDelete = id => {
  return Vue.$delete(`template/delete/${id}`)
}

// 批量删除
const templateDeleteBatch = data => {
  return Vue.$delete('template/deleteBatch', data)
}

// 分页查询
const templatePage = data => {
  return Vue.$get('template/page', data)
}

// 通过对象查询列表
const templateList = data => {
  return Vue.$get('template/list', data)
}

export { templateSave, templateUpdate, templateGet, templateDelete, templatePage, templateList, templateDeleteBatch }
