/**
 * 在主框架内显示
 */
const frameIn = []
/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('./index.vue')
  }
]

// 重新组织后导出
export default {
  frameIn,
  frameOut
}
