/*
 * @Author: lolxy
 * @Date: 2020-04-15 14:09:11
 * @LastEditors: lolxy
 * @LastEditTime: 2020-05-21 17:11:20
 * @Description:axios配置页
 * @Email: 604749526@qq.com
 * @Company: DEGAL
 * @YouWant:
 */

import _ from 'lodash'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import Axios from 'axios'
import { Message } from 'element-ui'
import util from '@/libs/util'
import Qs from 'qs'

// 创建一个错误
function errorCreate(msg) {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(`error:${msg}`)
  }

  // 显示提示
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  })
}

Axios.defaults.baseURL = process.env.VUE_APP_API
Axios.defaults.headers.common.Accept = 'application/json'
Axios.defaults.withCredentials = true
// Axios.defaults.timeout = 5000

// 请求拦截器
Axios.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    const token = util.cookies.get('token')
    const userId = util.cookies.get('uuid')
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers['token'] = token
    config.headers['userId'] = userId
    if (!['rule/save', 'rule/update'].includes(_.get(config, 'url', ''))) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.transformRequest = [
        function(data) {
          // 在请求之前对data传参进行格式转换
          data = Qs.stringify(data)
          return data
        }
      ]
    }

    return config
  },
  error => {
    // 发送失败
    console.log(error, 'request fail')
    return Promise.reject(error)
  }
)

// 响应拦截器
Axios.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = _.get(response, 'data')
    // 这个状态码是和后端约定的
    const { code } = dataAxios
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 EwtmAdmin 请求最新版本
      return dataAxios
    } else {
      switch (code) {
        case 200:
          return dataAxios
        case 40101:
          errorCreate(_.get(dataAxios, 'message', ''))
          util.cookies.remove('token')
          util.cookies.remove('uuid')
          router.replace('/login')
          break
        case 401:
          if (process.env.NODE_ENV === 'development') {
            errorCreate(`[ code: ${code} ] ${_.get(dataAxios, 'message', '')}:/api/${_.get(response, 'config.url')}`)
          } else {
            errorCreate(_.get(dataAxios, 'message', ''))
          }
          util.cookies.remove('token')
          util.cookies.remove('uuid')
          router.replace('/login')
          break
        case 403:
          store.dispatch('account/fetchLoginInfo', { userId: util.cookies.get('uuid') })
          if (process.env.NODE_ENV === 'development') {
            errorCreate(`[ code: ${code} ] ${_.get(dataAxios, 'message', '')}:/api/${_.get(response, 'config.url')}`)
          } else {
            errorCreate(_.get(dataAxios, 'message', ''))
          }
          break
        default:
          if (process.env.NODE_ENV === 'development') {
            errorCreate(`[ code: ${code} ] ${_.get(dataAxios, 'message', '')}:/api/${_.get(response, 'config.url')}`)
          } else {
            errorCreate(_.get(dataAxios, 'message', ''))
          }
          return dataAxios
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (_.get(error, 'response.status')) {
        case 400:
          error.response.message = '请求错误'
          break
        case 401:
          util.cookies.remove('token')
          util.cookies.remove('uuid')
          router.replace('/login')
          error.response.message = '未授权，请登录'
          break
        case 403:
          error.response.message = '拒绝访问'
          break
        case 404:
          error.response.message =
            process.env.NODE_ENV === 'development'
              ? `请求地址出错: ${_.get(error, 'response.config.url')}`
              : '请求的接口地址不存在'
          break
        case 408:
          error.response.message = '请求超时'
          break
        case 500:
          error.response.message = '服务器内部错误'
          break
        case 501:
          error.response.message = '服务未实现'
          break
        case 502:
          error.response.message = '网关错误'
          break
        case 503:
          error.response.message = '服务不可用'
          break
        case 504:
          error.response.message = '网关超时'
          break
        case 505:
          error.response.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    }
    console.log('response fail', error.response)
    return Promise.reject(_.get(error, 'response'))
  }
)

Vue.$axios = Axios

Object.defineProperty(Vue.prototype, '$axios', {
  get() {
    return Axios
  }
})

Vue.$get = Vue.prototype.$get = (url, params) => {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: params
    })
      .then(({ code, data, message }) => {
        if (code && code === 200) {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

Vue.$post = Vue.prototype.$post = (url, params) => {
  return new Promise((resolve, reject) => {
    Axios.post(url, params)
      .then(({ code, data, message }) => {
        if (code && code === 200) {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

Vue.$put = Vue.prototype.$put = (url, params) => {
  return new Promise((resolve, reject) => {
    Axios.put(url, params)
      .then(({ code, data, message }) => {
        if (code && code === 200) {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

Vue.$delete = Vue.prototype.$delete = (url, params) => {
  return new Promise((resolve, reject) => {
    Axios.delete(url, {
      params: params
    })
      .then(({ code, data, message }) => {
        if (code && code === 200) {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

Vue.$getBolbFile = Vue.prototype.$getBolbFile = (url, params) => {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: params,
      responseType: 'blob'
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default Axios
