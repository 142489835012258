var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quill-editor" },
    [
      _c("el-upload", {
        staticClass: "quill-upload",
        attrs: {
          id: "quill-upload",
          action: _vm.serverUrl,
          headers: _vm.header,
          "show-file-list": false,
          "on-success": _vm.uploadSuccess,
          "on-error": _vm.uploadError,
          "before-upload": _vm.beforeUpload,
        },
      }),
      _c("quill-editor", {
        ref: "myQuillEditor",
        attrs: { options: _vm.editorOption },
        on: {
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }