<template>
  <div class="global-container-ghost-bs">
    <div v-if="$slots.header" class="global-container-ghost-bs__header" ref="header">
      <slot name="header" />
    </div>
    <div class="global-container-ghost-bs__body" ref="wrapper">
      <!-- https://github.com/d2-projects/d2-admin/issues/181 -->
      <div>
        <slot />
      </div>
    </div>
    <div v-if="$slots.footer" class="global-container-ghost-bs__footer" ref="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import bs from './mixins/bs'
export default {
  name: 'global-container-card-bs',
  mixins: [bs]
}
</script>
