import Vue from 'vue'

// 添加
const agreementRuleSave = data => {
  return Vue.$post('agreementRule/save', data)
}

// 修改
const agreementRuleUpdate = data => {
  return Vue.$put('agreementRule/update', data)
}

// 根据ID查询单个
const agreementRuleGet = id => {
  return Vue.$get(`agreementRule/get/${id}`)
}

// 删除单个
const agreementRuleDelete = id => {
  return Vue.$delete(`agreementRule/delete/${id}`)
}

// 批量删除
const agreementRuleDeleteBatch = data => {
  return Vue.$delete('agreementRule/deleteBatch', data)
}

// 分页查询
const agreementRulePage = data => {
  return Vue.$get('agreementRule/page', data)
}

// 通过对象查询列表
const agreementRuleList = data => {
  return Vue.$get('agreementRule/list', data)
}

export {
  agreementRuleSave,
  agreementRuleUpdate,
  agreementRuleGet,
  agreementRuleDelete,
  agreementRulePage,
  agreementRuleList,
  agreementRuleDeleteBatch
}
