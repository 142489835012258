import Vue from 'vue'

// 添加
const repaymentAgreementOrderSave = data => {
  return Vue.$post('repaymentAgreementOrder/save', data)
}

// 修改
const repaymentAgreementOrderUpdate = data => {
  return Vue.$put('repaymentAgreementOrder/update', data)
}

// 根据ID查询单个
const repaymentAgreementOrderGet = id => {
  return Vue.$get(`repaymentAgreementOrder/get/${id}`)
}

// 删除单个
const repaymentAgreementOrderDelete = id => {
  return Vue.$delete(`repaymentAgreementOrder/delete/${id}`)
}

// 批量删除
const repaymentAgreementOrderDeleteBatch = data => {
  return Vue.$delete('repaymentAgreementOrder/deleteBatch', data)
}

// 分页查询
const repaymentAgreementOrderPage = data => {
  return Vue.$get('repaymentAgreementOrder/page', data)
}

// 通过对象查询列表
const repaymentAgreementOrderList = data => {
  return Vue.$get('repaymentAgreementOrder/list', data)
}

export {
  repaymentAgreementOrderSave,
  repaymentAgreementOrderUpdate,
  repaymentAgreementOrderGet,
  repaymentAgreementOrderDelete,
  repaymentAgreementOrderPage,
  repaymentAgreementOrderList,
  repaymentAgreementOrderDeleteBatch
}
