import _ from 'lodash'
import { listByDictType } from '@api'

export default {
  name: 'global-select-list',

  props: {
    source: [String, Number, Array],
    prop: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    formType: {
      type: String,
      default: 'select'
    }
  },

  data() {
    return {
      label: '',
      value: '',
      options: []
    }
  },

  watch: {
    source(value) {
      this.value = value
    },

    type() {
      this.getOptionsList()
    }
  },

  mounted() {
    this.getOptionsList()

    if (this.multiple) {
      this.value = []
    }

    if (this.source) {
      this.value = this.source
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', { prop: this.prop, value })
    },

    getOptionsList() {
      if (this.type) {
        listByDictType(this.type).then(res => {
          this.label = _.get(res, '0.dictName', '')
          this.value = this.value ? this.value : _.get(res, '0.dictKey', '')
          this.onChange(this.value)
          this.options = _.map(_.filter(res, { enable: 'T' }), elem => {
            return {
              id: elem.id,
              label: elem.dictValue,
              value: elem.dictKey
            }
          })
        })
      }
    }
  }
}
