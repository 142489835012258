/*
 * @Author: lolxy
 * @Date: 2020-06-01 23:30:13
 * @LastEditors: lolxy
 * @LastEditTime: 2020-06-01 23:49:07
 * @Description: file content
 * @Email: 604749526@qq.com
 * @Company: DEGAL
 * @FilePath: \fc-web-appf:\baseadmin\src\views\sys\param\api.js
 */

import Vue from 'vue'

// 添加
const sysParamSave = data => {
  return Vue.$post('sysParam/save', data)
}

// 修改
const sysParamUpdate = data => {
  return Vue.$put('sysParam/update', data)
}

// 根据ID查询单个
const sysParamGet = id => {
  return Vue.$get(`sysParam/get/${id}`)
}

// 删除单个
const sysParamDelete = id => {
  return Vue.$delete(`sysParam/delete/${id}`)
}

// 批量删除
const sysParamDeleteBatch = data => {
  return Vue.$delete('sysParam/deleteBatch', data)
}

// 分页查询
const sysParamPage = data => {
  return Vue.$get('sysParam/page', data)
}

// 通过对象查询列表
const sysParamList = data => {
  return Vue.$get('sysParam/list', data)
}

// 根据key获取value值
const sysParamValueBykey = key => {
  return Vue.$get('/sysParam/getValueBykey', { key })
}

export {
  sysParamSave,
  sysParamUpdate,
  sysParamGet,
  sysParamDelete,
  sysParamPage,
  sysParamList,
  sysParamDeleteBatch,
  sysParamValueBykey
}
