/**
 * 在主框架内显示
 */
const frameIn = [
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: () => import('./refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: () => import('./redirect')
  }
]
/**
 * 在主框架之外显示
 */
const frameOut = []

// 重新组织后导出
export default {
  frameIn,
  frameOut
}
