import _ from 'lodash'

export default {
  name: 'global-filter',

  props: {
    placeholder: {
      type: String,
      default: '请输入搜索的关键字'
    }
  },

  data() {
    return {
      keyword: ''
    }
  },

  computed: {
    query() {
      return this.$route.query
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.keyword = _.get(this.$route, 'query.keyword', '')
    },

    onSearch() {
      const queryObj = this.keyword
        ? {
            ...this.query,
            keyword: this.keyword
          }
        : _.omit(this.query, 'keyword')

      this.$router.replace({
        name: this.$route.name,
        query: queryObj
      })
    }
  }
}
