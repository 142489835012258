/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: 'my/info',
    name: 'my.info',
    meta: {
      title: '个人信息',
      auth: true
    },
    component: () => import('./info/index.vue')
  },
  {
    path: 'my/password',
    name: 'my.password',
    meta: {
      title: '修改密码',
      auth: true
    },
    component: () => import('./password/index.vue')
  }
]
/**
 * 在主框架之外显示
 */
const frameOut = []

// 重新组织后导出
export default {
  frameIn,
  frameOut
}
