import Vue from 'vue'

// 角色添加
const roleSave = data => {
  return Vue.$post('sysRole/save', data)
}

// 角色修改
const roleUpdate = data => {
  return Vue.$put('sysRole/update', data)
}

// 根据ID查询单个
const roleGet = id => {
  return Vue.$get(`sysRole/get/${id}`)
}

// 删除单个
const roleDelete = id => {
  return Vue.$delete(`sysRole/delete/${id}`)
}

// 批量删除
const roleDeleteBatch = data => {
  return Vue.$delete('sysRole/deleteBatch', data)
}

// 分页查询
const rolePage = data => {
  return Vue.$get('sysRole/page', data)
}

export { roleSave, roleUpdate, roleGet, roleDelete, rolePage, roleDeleteBatch }
