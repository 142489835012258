import Vue from 'vue'

// 添加
const companySave = data => {
  return Vue.$post('company/save', data)
}

// 修改
const companyUpdate = data => {
  return Vue.$put('company/update', data)
}

// 通过审核
const companyPass = id => {
  return Vue.$put('company/pass', { id })
}

// 驳回申请
const companyReject = data => {
  return Vue.$put('company/reject', data)
}

// 根据ID查询单个
const companyGet = id => {
  return Vue.$get(`company/get/${id}`)
}

// 删除单个
const companyDelete = id => {
  return Vue.$delete(`company/delete/${id}`)
}

// 批量删除
const companyDeleteBatch = data => {
  return Vue.$delete('company/deleteBatch', data)
}

// 分页查询
const companyPage = data => {
  return Vue.$get('company/page', data)
}

// 通过对象查询列表
const companyList = data => {
  return Vue.$get('company/list', data)
}

// 获取注册协议
const companyListAgreementTemplate = () => {
  return Vue.$get('company/listAgreementTemplate')
}

//提交审核
const companySubmitAudit = data => {
  return Vue.$post('company/submitAudit', data)
}

export {
  companySave,
  companyUpdate,
  companyGet,
  companyDelete,
  companyPage,
  companyList,
  companyDeleteBatch,
  companyReject,
  companyPass,
  companySubmitAudit,
  companyListAgreementTemplate
}
