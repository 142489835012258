import Vue from 'vue'

// 添加
const personSave = data => {
  return Vue.$post('person/save', data)
}

// 修改
const personUpdate = data => {
  return Vue.$put('person/update', data)
}

// 根据ID查询单个
const personGet = id => {
  return Vue.$get(`person/get/${id}`)
}

// 删除单个
const personDelete = id => {
  return Vue.$delete(`person/delete/${id}`)
}

// 批量删除
const personDeleteBatch = data => {
  return Vue.$delete('person/deleteBatch', data)
}

// 分页查询
const personPage = data => {
  return Vue.$get('person/page', data)
}

// 通过对象查询列表
const personList = data => {
  return Vue.$get('person/list', data)
}

export { personSave, personUpdate, personGet, personDelete, personPage, personList, personDeleteBatch }
