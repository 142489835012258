import Vue from 'vue'

// 添加
const lawyerSave = data => {
  return Vue.$post('lawyer/save', data)
}

// 修改
const lawyerUpdate = data => {
  return Vue.$put('lawyer/update', data)
}

// 根据ID查询单个
const lawyerGet = id => {
  return Vue.$get(`lawyer/get/${id}`)
}

// 删除单个
const lawyerDelete = id => {
  return Vue.$delete(`lawyer/delete/${id}`)
}

// 批量删除
const lawyerDeleteBatch = data => {
  return Vue.$delete('lawyer/deleteBatch', data)
}

// 分页查询
const lawyerPage = data => {
  return Vue.$get('lawyer/page', data)
}

// 通过对象查询列表
const lawyerList = data => {
  return Vue.$get('lawyer/list', data)
}

export { lawyerSave, lawyerUpdate, lawyerGet, lawyerDelete, lawyerPage, lawyerList, lawyerDeleteBatch }
