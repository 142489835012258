import Vue from 'vue'

// 添加
const userAccSave = data => {
  return Vue.$post('userAcc/save', data)
}

// 修改
const userAccUpdate = data => {
  return Vue.$put('userAcc/update', data)
}

// 根据ID查询单个
const userAccGet = id => {
  return Vue.$get(`userAcc/get/${id}`)
}

// 删除单个
const userAccDelete = id => {
  return Vue.$delete(`userAcc/delete/${id}`)
}

// 批量删除
const userAccDeleteBatch = data => {
  return Vue.$delete('userAcc/deleteBatch', data)
}

// 分页查询
const userAccPage = data => {
  return Vue.$get('userAcc/page', data)
}

// 通过对象查询列表
const userAccList = data => {
  return Vue.$get('userAcc/list', data)
}

export { userAccSave, userAccUpdate, userAccGet, userAccDelete, userAccPage, userAccList, userAccDeleteBatch }
