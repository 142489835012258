import Vue from 'vue'

// 添加
const mediateTemplateSave = data => {
  return Vue.$post('mediateTemplate/save', data)
}

// 修改
const mediateTemplateUpdate = data => {
  return Vue.$put('mediateTemplate/update', data)
}

// 根据ID查询单个
const mediateTemplateGet = id => {
  return Vue.$get(`mediateTemplate/get/${id}`)
}

// 删除单个
const mediateTemplateDelete = id => {
  return Vue.$delete(`mediateTemplate/delete/${id}`)
}

// 批量删除
const mediateTemplateDeleteBatch = data => {
  return Vue.$delete('mediateTemplate/deleteBatch', data)
}

// 分页查询
const mediateTemplatePage = data => {
  return Vue.$get('mediateTemplate/page', data)
}

// 通过对象查询列表
const mediateTemplateList = data => {
  return Vue.$get('mediateTemplate/list', data)
}

// 修改
const mediateTemplateUpdateEnable = data => {
  return Vue.$put('mediateTemplate/updateEnable', data)
}

export {
  mediateTemplateSave,
  mediateTemplateUpdate,
  mediateTemplateGet,
  mediateTemplateDelete,
  mediateTemplatePage,
  mediateTemplateList,
  mediateTemplateDeleteBatch,
  mediateTemplateUpdateEnable
}
