import Vue from 'vue'

// 添加
const productSave = data => {
  return Vue.$post('product/save', data)
}

// 修改
const productUpdate = data => {
  return Vue.$put('product/update', data)
}

// 根据ID查询单个
const productGet = id => {
  return Vue.$get(`product/get/${id}`)
}

// 删除单个
const productDelete = id => {
  return Vue.$delete(`product/delete/${id}`)
}

// 批量删除
const productDeleteBatch = data => {
  return Vue.$delete('product/deleteBatch', data)
}

// 分页查询
const productPage = data => {
  return Vue.$get('product/page', data)
}

// 通过对象查询列表
const productList = data => {
  return Vue.$get('product/list', data)
}

const productUpdateEnable = data => {
  return Vue.$put('product/updateEnable', data)
}
export {
  productSave,
  productUpdate,
  productGet,
  productDelete,
  productPage,
  productList,
  productDeleteBatch,
  productUpdateEnable
}
