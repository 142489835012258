var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-search", attrs: { flex: "dir:top" } },
    [
      _c(
        "div",
        {
          staticClass: "panel-search__input-group",
          attrs: { "flex-box": "0", flex: "dir:top main:center cross:center" },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handlePanelClick.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "logo" }, [
            _c("img", { attrs: { src: _vm.searchLogo, alt: "" } }),
          ]),
          _c("el-autocomplete", {
            ref: "input",
            staticClass: "panel-search__input",
            attrs: {
              "suffix-icon": "el-icon-search",
              placeholder: "搜索页面",
              "fetch-suggestions": _vm.querySearch,
              "trigger-on-focus": false,
              clearable: true,
            },
            on: { select: _vm.handleSelect },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                ) {
                  return null
                }
                return _vm.handleEsc.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return _c("global-panel-search-item", {
                    attrs: { item: item },
                  })
                },
              },
            ]),
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c("div", { staticClass: "panel-search__tip" }, [
            _vm._v(" 您可以使用快捷键 "),
            _c("span", { staticClass: "panel-search__key" }, [
              _vm._v(_vm._s(_vm.hotkey.open)),
            ]),
            _vm._v(" 唤醒搜索面板，按 "),
            _c("span", { staticClass: "panel-search__key" }, [
              _vm._v(_vm._s(_vm.hotkey.close)),
            ]),
            _vm._v(" 关闭 "),
          ]),
        ],
        1
      ),
      _vm.resultsList.length > 0
        ? _c(
            "div",
            {
              staticClass: "panel-search__results-group",
              attrs: { "flex-box": "1" },
            },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "panel-search__results-group-inner" },
                  _vm._l(_vm.resultsList, function (item, index) {
                    return _c("global-panel-search-item", {
                      key: index,
                      attrs: { item: item, "hover-mode": true },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleResultsGroupItemClick(item.path)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }