import Vue from 'vue'

// 添加
const sysDictSave = data => {
  return Vue.$post('sysDict/save', data)
}

// 修改
const sysDictUpdate = data => {
  return Vue.$put('sysDict/update', data)
}

// 根据ID查询单个
const sysDictGet = id => {
  return Vue.$get(`sysDict/get/${id}`)
}

// 删除单个
const sysDictDelete = id => {
  return Vue.$delete(`sysDict/delete/${id}`)
}

// 批量删除
const sysDictDeleteBatch = data => {
  return Vue.$delete('sysDict/deleteBatch', data)
}

// 分页查询
const sysDictPage = data => {
  return Vue.$get('sysDict/page', data)
}

// 通过对象查询列表
const sysDictList = data => {
  return Vue.$get('sysDict/list', data)
}

// 查询下拉框值
const listByDictType = dictType => {
  return Vue.$get('sysDict/listByDictType', { dictType })
}

export {
  sysDictSave,
  sysDictUpdate,
  sysDictGet,
  sysDictDelete,
  sysDictPage,
  sysDictList,
  sysDictDeleteBatch,
  listByDictType
}
