<template>
  <div class="global-layout-header-aside-group" :style="styleLayoutMainGroup" :class="{ grayMode: grayActive }">
    <!-- 半透明遮罩 -->
    <div class="global-layout-header-aside-mask"></div>
    <!-- 主体内容 -->
    <div class="global-layout-header-aside-content" flex="dir:top">
      <!-- 顶栏 -->
      <div
        class="global-theme-header"
        :style="{
          opacity: this.searchActive ? 0.5 : 1
        }"
        flex-box="0"
        flex
      >
        <div class="logo-wrap">
          <router-link to="/index" class="logo-group" flex-box="0">
            <!-- <img v-if="asideCollapse" :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/icon-only.png`" /> -->
            <img :src="logo" />
          </router-link>
          <span class="txt">{{ sysName }}</span>
        </div>

        <!-- <global-menu-header flex-box="1" /> -->
        <!-- 顶栏右侧 -->
        <div class="global-header-right" flex-box="0">
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
          <!-- <global-header-search @click="handleSearchClick" /> -->
          <!-- <global-header-log /> -->
          <global-header-fullscreen />
          <!-- <global-header-theme /> -->
          <!-- <global-header-size /> -->
          <!-- <global-header-locales /> -->
          <!-- <global-header-color /> -->
          <global-header-user />
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="global-theme-container" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <div
          flex-box="0"
          ref="aside"
          class="global-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }"
        >
          <global-menu-side />
        </div>
        <!-- 主体 -->
        <div class="global-theme-container-main" flex-box="1" flex>
          <!-- 搜索 -->
          <transition name="fade-scale">
            <div v-if="searchActive" class="global-theme-container-main-layer" flex>
              <global-panel-search ref="panelSearch" @close="searchPanelClose" />
            </div>
          </transition>
          <!-- 内容 -->
          <transition name="fade-scale">
            <div v-if="!searchActive" class="global-theme-container-main-layer" flex="dir:top">
              <!-- tab -->
              <div class="global-theme-container-main-header" flex-box="0">
                <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0">
                  <global-icon name="bars" />
                </div>
                <global-tabs />
              </div>
              <!-- 页面 -->
              <div class="global-theme-container-main-body" flex-box="1">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :include="keepAlive">
                    <globalMainBody>
                      <router-view />
                    </globalMainBody>
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import globalMenuSide from './components/menu-side'
// import globalMenuHeader from './components/menu-header'
import globalTabs from './components/tabs'
import globalHeaderFullscreen from './components/header-fullscreen'
// import globalHeaderLocales from './components/header-locales'
// import globalHeaderSearch from './components/header-search'
// import globalHeaderSize from './components/header-size'
// import globalHeaderTheme from './components/header-theme'
import globalHeaderUser from './components/header-user'
// import globalHeaderLog from './components/header-log'
// import globalHeaderColor from './components/header-color'
import globalMainBody from './components/main-body'
import { mapState, mapGetters, mapActions } from 'vuex'
import mixinSearch from './mixins/search'
export default {
  name: 'global-layout-header-aside',
  mixins: [mixinSearch],
  components: {
    globalMenuSide,
    // globalMenuHeader,
    globalTabs,
    globalHeaderFullscreen,
    // globalHeaderLocales,
    // globalHeaderSearch,
    // globalHeaderSize,
    // globalHeaderTheme,
    globalHeaderUser,
    // globalHeaderLog,
    // globalHeaderColor,
    globalMainBody
  },
  data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '64px'
    }
  },
  computed: {
    ...mapState({
      keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse,
      logo: state => _.get(state, 'site.siteInfo.indexLogo', ''),
      sysName: state => _.get(state, 'site.siteInfo.indexSysName', '')
    }),
    ...mapGetters('theme', {
      themeActiveSetting: 'activeSetting'
    }),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup() {
      return {
        ...(this.themeActiveSetting.backgroundImage
          ? {
              backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`
            }
          : {})
      }
    }
  },
  methods: {
    ...mapActions('menu', ['asideCollapseToggle']),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle()
    }
  }
}
</script>

<style lang="scss">
// 注册主题
@import '~@/assets/style/theme/register.scss';
</style>
