<template>
  <el-dropdown size="small" class="global-mr">
    <span class="btn-text">{{ info.name ? `${info.realName} - ${info.name}` : '未登录' }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="$router.push('/my/info')">
        <global-icon name="user" class="global-mr-5" />
        个人信息
      </el-dropdown-item>
      <el-dropdown-item @click.native="$router.push('/my/password')">
        <global-icon name="key" class="global-mr-5" />
        修改密码
      </el-dropdown-item>
      <el-dropdown-item @click.native="logOff">
        <global-icon name="power-off" class="global-mr-5" />
        注销
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('user', ['info'])
  },
  methods: {
    ...mapActions('account', ['logout']),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true
      })
    }
  }
}
</script>
