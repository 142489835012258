/*
 * @Author: lolxy
 * @Date: 2020-02-10 20:15:20
 * @LastEditors: lolxy
 * @LastEditTime: 2020-06-01 23:02:28
 * @Description: file content
 * @Email: 604749526@qq.com
 * @Company:
 * @FilePath: \fc-web-appf:\globalui\src\store\modules\releases.js
 */

import util from '@/libs/util.js'

export default {
  namespaced: true,
  mutations: {
    /**
     * @description 显示版本信息
     * @param {Object} state state
     */
    versionShow() {
      util.log.capsule('GlobalAdmin', `v${process.env.VUE_APP_VERSION}`)
      // console.log('D2 Admin  https://github.com/d2-projects/d2-admin')
      // console.log('D2 Crud   https://github.com/d2-projects/d2-crud')
      // console.log('Document  https://d2.pub/zh/doc/d2-admin')
    }
  }
}
