import Vue from 'vue'

import globalContainer from './global-container'
import globalFilter from './global-filter/main.vue'
import globalSelect from './global-select/main.vue'
import globalPager from './global-pager/main.vue'
import globalNoAuth from './global-noauth'
import globalEditor from './global-quill'
import globalUpload from './global-upload/main.vue'
import globalAddress from './global-address/main.vue'
import globalExcel from './global-excel/main.vue'

// 注意 有些组件使用异步加载会有影响
Vue.component('global-container', globalContainer)
Vue.component('global-filter', globalFilter)
Vue.component('global-upload', globalUpload)
Vue.component('global-editor', globalEditor)
Vue.component('global-pager', globalPager)
Vue.component('global-noauth', globalNoAuth)
Vue.component('global-select', globalSelect)
Vue.component('global-address', globalAddress)
Vue.component('global-excel', globalExcel)
Vue.component('global-icon', () => import('./global-icon'))
Vue.component('global-icon-svg', () => import('./global-icon-svg/index.vue'))
