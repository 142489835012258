import Vue from 'vue'

// 添加
const layFirmInfoSave = data => {
  return Vue.$post('layFirmInfo/save', data)
}

// 修改
const layFirmInfoUpdate = data => {
  return Vue.$put('layFirmInfo/update', data)
}

// 根据ID查询单个
const layFirmInfoGet = id => {
  return Vue.$get(`layFirmInfo/get/${id}`)
}

// 删除单个
const layFirmInfoDelete = id => {
  return Vue.$delete(`layFirmInfo/delete/${id}`)
}

// 批量删除
const layFirmInfoDeleteBatch = data => {
  return Vue.$delete('layFirmInfo/deleteBatch', data)
}

// 分页查询
const layFirmInfoPage = data => {
  return Vue.$get('layFirmInfo/page', data)
}

// 通过对象查询列表
const layFirmInfoList = data => {
  return Vue.$get('layFirmInfo/list', data)
}

export {
  layFirmInfoSave,
  layFirmInfoUpdate,
  layFirmInfoGet,
  layFirmInfoDelete,
  layFirmInfoPage,
  layFirmInfoList,
  layFirmInfoDeleteBatch
}
