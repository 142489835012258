import { mapState } from 'vuex'
import menuMixin from '../mixin/menu'
import { elMenuItem, elSubmenu } from '../libs/util.menu'
import BScroll from 'better-scroll'

export default {
  name: 'global-layout-header-aside-menu-side',
  mixins: [menuMixin],
  render(createElement) {
    return createElement('div', { attrs: { class: 'global-layout-header-aside-menu-side' } }, [
      createElement(
        'el-menu',
        {
          props: { collapse: this.asideCollapse, uniqueOpened: true, defaultActive: this.active },
          ref: 'menu',
          on: { select: this.handleMenuSelect }
        },
        this.formatAside.map(menu =>
          (menu.children === undefined ? elMenuItem : elSubmenu).call(this, createElement, menu)
        )
      ),
      ...(this.formatAside.length === 0 && !this.asideCollapse
        ? [
            createElement(
              'div',
              { attrs: { class: 'global-layout-header-aside-menu-empty', flex: 'dir:top main:center cross:center' } },
              [createElement('global-icon', { props: { name: 'inbox' } }), createElement('span', {}, '没有侧栏菜单')]
            )
          ]
        : [])
    ])
  },
  data() {
    return {
      active: '',
      asideHeight: 300,
      BS: null
    }
  },
  computed: {
    ...mapState('menu', ['aside', 'asideCollapse']),

    formatAside() {
      return _.map(this.aside, item => {
        if (item.resourceCode === 'ziliao' && this.$userId === '232323') {
          return {
            ...item,
            children: _.filter(
              item.children,
              elem => !['companyInfo', 'lawyerInfo', 'personInfo'].includes(elem.resourceCode)
            )
          }
        }
        return item
      })
    }
  },
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse(val) {
      this.scrollDestroy()
      setTimeout(() => {
        this.scrollInit()
      }, 500)
    },
    // 监听路由 控制侧边栏激活状态
    // '$route.fullPath': {
    //   handler (value) {
    //     this.active = value
    //   },
    //   immediate: true
    // }
    // 监听路由 控制侧边栏激活状态
    '$route.matched': {
      handler(val) {
        const obj = {
          path: val[val.length - 1].path,
          link: 'F'
        }
        this.active = JSON.stringify(obj)
        this.$nextTick(() => {
          if (this.formatAside.length > 0 && this.$refs.menu) {
            this.$refs.menu.activeIndex = this.active
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.scrollInit()
  },
  beforeDestroy() {
    this.scrollDestroy()
  },
  methods: {
    scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true
        // 如果你愿意可以打开显示滚动条
        // scrollbar: {
        //   fade: true,
        //   interactive: false
        // }
      })
    },
    scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy()
      } catch (e) {
        delete this.BS
        this.BS = null
      }
    }
  }
}
