var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "global-mr", attrs: { size: "small" } },
    [
      _c("span", { staticClass: "btn-text" }, [
        _vm._v(
          _vm._s(
            _vm.info.name ? _vm.info.realName + " - " + _vm.info.name : "未登录"
          )
        ),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.$router.push("/my/info")
                },
              },
            },
            [
              _c("global-icon", {
                staticClass: "global-mr-5",
                attrs: { name: "user" },
              }),
              _vm._v(" 个人信息 "),
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.$router.push("/my/password")
                },
              },
            },
            [
              _c("global-icon", {
                staticClass: "global-mr-5",
                attrs: { name: "key" },
              }),
              _vm._v(" 修改密码 "),
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.logOff.apply(null, arguments)
                },
              },
            },
            [
              _c("global-icon", {
                staticClass: "global-mr-5",
                attrs: { name: "power-off" },
              }),
              _vm._v(" 注销 "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }