import Vue from 'vue'

// 添加
const repaymentMethodSave = data => {
  return Vue.$post('repaymentMethod/save', data)
}

// 修改
const repaymentMethodUpdate = data => {
  return Vue.$put('repaymentMethod/update', data)
}

// 根据ID查询单个
const repaymentMethodGet = id => {
  return Vue.$get(`repaymentMethod/get/${id}`)
}

// 删除单个
const repaymentMethodDelete = id => {
  return Vue.$delete(`repaymentMethod/delete/${id}`)
}

// 批量删除
const repaymentMethodDeleteBatch = data => {
  return Vue.$delete('repaymentMethod/deleteBatch', data)
}

// 分页查询
const repaymentMethodPage = data => {
  return Vue.$get('repaymentMethod/page', data)
}

// 通过对象查询列表
const repaymentMethodList = data => {
  return Vue.$get('repaymentMethod/list', data)
}

// 是否启用
const repaymentMethodEnable = data => {
  return Vue.$put('/repaymentMethod/updateEnable', data)
}

export {
  repaymentMethodSave,
  repaymentMethodUpdate,
  repaymentMethodGet,
  repaymentMethodDelete,
  repaymentMethodPage,
  repaymentMethodList,
  repaymentMethodDeleteBatch,
  repaymentMethodEnable
}
