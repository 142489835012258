import Vue from 'vue'

// 添加
const productCategorySave = data => {
  return Vue.$post('productCategory/save', data)
}

// 修改
const productCategoryUpdate = data => {
  return Vue.$put('productCategory/update', data)
}

// 根据ID查询单个
const productCategoryGet = id => {
  return Vue.$get(`productCategory/get/${id}`)
}

// 删除单个
const productCategoryDelete = id => {
  return Vue.$delete(`productCategory/delete/${id}`)
}

// 批量删除
const productCategoryDeleteBatch = data => {
  return Vue.$delete('productCategory/deleteBatch', data)
}

// 分页查询
const productCategoryPage = data => {
  return Vue.$get('productCategory/page', data)
}

// 通过对象查询列表
const productCategoryList = data => {
  return Vue.$get('productCategory/list', data)
}

//树
const productCategoryListTree = data => {
  return Vue.$get('productCategory/listTree', data)
}

export {
  productCategorySave,
  productCategoryUpdate,
  productCategoryGet,
  productCategoryDelete,
  productCategoryPage,
  productCategoryList,
  productCategoryListTree,
  productCategoryDeleteBatch
}
