import Vue from 'vue'

// 重置密码
const resetPassword = id => {
  return Vue.$put(`sysUser/resetPassword/${id}`)
}

// 个人修改密码
const modifyPassword = data => {
  return Vue.$put('sysUser/modifyPassword', data)
}

export { resetPassword, modifyPassword }
