import _ from 'lodash'
import { Message, MessageBox } from 'element-ui'
import util from '@/libs/util.js'
import router from '@/router'
import { AccountLogin, AccountLogout, AccountLoginInfo } from '@api'

export default {
  namespaced: true,
  state: {
    // 用户信息
    menu: [],
    buttons: [],
    permission: [],
    roles: []
  },
  actions: {
    /**
     * @description 登录
     * @param {Object} context
     * @param {Object} payload username {String} 用户账号
     * @param {Object} payload password {String} 密码
     * @param {Object} payload route {Object} 登录成功后定向的路由对象 任何 vue-router 支持的格式
     */
    login({ dispatch }, data = {}) {
      return new Promise((resolve, reject) => {
        AccountLogin(data)
          .then(async res => {
            // 设置 cookie 一定要存 uuid 和 token 两个 cookie
            // 整个系统依赖这两个数据进行校验和存储
            // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
            // token 代表用户当前登录状态 建议在网络请求中携带 token
            // 如有必要 token 需要定时更新，默认保存一天
            util.cookies.set('uuid', res.userId)
            util.cookies.set('token', res.token)
            dispatch('page/closeAll', {}, { root: true })
            await dispatch('user/set', { name: res.loginName, ...res }, { root: true })
            // 设置 vuex 用户信息
            // await dispatch('fetchLoginInfo', { userId: res.userId })
            // 用户登录后从持久化数据加载一系列的设置
            await dispatch('load')
            // 结束
            resolve()
          })
          .catch(err => {
            console.log('err: ', err)
            reject(err)
          })
      })
    },
    /**
     * @description 获取登录信息
     * @param {Object} payload userId {String} 用户id
     */
    fetchLoginInfo({ state }, { userId }) {
      if (userId) {
        const formatList = item => {
          return [
            {
              ..._.pick(item, ['id', 'resourceCode', 'isExtend', 'resourceName', 'resourceType', 'url'])
            },
            ..._.map(item.children, elem => formatList(elem))
          ]
        }

        return new Promise((resolve, reject) => {
          AccountLoginInfo(userId)
            .then(res => {
              // 设置 vuex 用户信息
              state.menu = _.sortBy(res.menus, 'sort')
              state.buttons = res.buttons
              state.roles = res.roles
              state.permission = _.flatMapDeep(res.menus.concat(res.buttons), item => formatList(item))
              // 结束
              resolve()
            })
            .catch(err => {
              console.log('err: ', err)
              reject(err)
            })
        })
      }
    },
    /**
     * @description 注销用户并返回登录页面
     * @param {Object} context
     * @param {Object} payload confirm {Boolean} 是否需要确认
     */
    logout({ commit, dispatch }, { confirm = false } = {}) {
      /**
       * @description 注销
       */
      const onLogout = () => {
        AccountLogout()
          .then(res => {
            Message({
              type: 'success',
              message: res.msg
            })
            logout()
          })
          .catch(err => {
            logout()
            console.log('err: ', err)
          })
      }

      async function logout() {
        // 删除cookie
        util.cookies.remove('token')
        util.cookies.remove('uuid')
        // 清空 vuex 用户信息
        await dispatch('user/set', {}, { root: true })
        dispatch('page/closeAll', {}, { root: true })
        // 跳转路由
        router.push({
          name: 'login'
        })
      }

      // 判断是否需要确认
      if (confirm) {
        commit('gray/set', true, { root: true })
        MessageBox.confirm('确定要注销当前用户吗', '注销用户', {
          type: 'warning'
        })
          .then(() => {
            commit('gray/set', false, { root: true })
            onLogout()
          })
          .catch(() => {
            commit('gray/set', false, { root: true })
          })
      } else {
        onLogout()
      }
    },
    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} context
     */
    load({ dispatch }) {
      return new Promise(async resolve => {
        const uuid = util.cookies.get('uuid')
        // DB -> store 加载用户名
        await dispatch('user/load', null, { root: true })
        // DB -> store 获取资源
        if (uuid) {
          await dispatch('fetchLoginInfo', { userId: uuid })
          await dispatch('menu/fetchMenuList', {}, { root: true })
          // 设置侧边栏菜单
          await dispatch('menu/asideSet', {}, { root: true })
        }
        // 加载站点信息
        await dispatch('site/getSkinInfo', {}, { root: true })
        // DB -> store 加载主题
        await dispatch('theme/load', null, { root: true })
        // DB -> store 加载页面过渡效果设置
        await dispatch('transition/load', null, { root: true })
        // DB -> store 持久化数据加载上次退出时的多页列表
        await dispatch('page/openedLoad', null, { root: true })
        // DB -> store 持久化数据加载侧边栏折叠状态
        await dispatch('menu/asideCollapseLoad', null, { root: true })
        // DB -> store 持久化数据加载全局尺寸
        await dispatch('size/load', null, { root: true })
        // DB -> store 持久化数据加载颜色设置
        await dispatch('color/load', null, { root: true })
        // end
        resolve()
      })
    }
  }
}
