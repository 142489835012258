export default {
  install(Vue, options) {
    // 校验身份证号码
    Vue.prototype.$isCard = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的身份证号'))
        }
      }
    }

    // 校验电话号码
    Vue.prototype.$isTel = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机'))
        }
      }
    }

    Vue.prototype.$isLandline = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const reg = /^(\d{3,4}-)?\d{7,8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的电话'))
        }
      }
    }

    // 校验银行卡号
    Vue.prototype.$isBank = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        let lastNum = value.substr(value.length - 1, 1) // 取出最后一位（与luhm进行比较）
        let first15Num = value.substr(0, value.length - 1) // 前15或18位
        let newArr = []
        for (let i = first15Num.length - 1; i > -1; i--) {
          // 前15或18位倒序存进数组
          newArr.push(first15Num.substr(i, 1))
        }
        let arrJiShu = [] // 奇数位*2的积 < 9
        let arrJiShu2 = [] // 奇数位*2的积 >9

        let arrOuShu = [] // 偶数位数组
        for (let j = 0; j < newArr.length; j++) {
          if ((j + 1) % 2 === 1) {
            // 奇数位
            if (parseInt(newArr[j]) * 2 < 9) {
              arrJiShu.push(parseInt(newArr[j]) * 2)
            } else {
              arrJiShu2.push(parseInt(newArr[j]) * 2)
            }
          } else {
            arrOuShu.push(newArr[j])
          } // 偶数位
        }
        let jishuChild1 = [] // 奇数位*2 >9 的分割之后的数组个位数
        let jishuChild2 = [] // 奇数位*2 >9 的分割之后的数组十位数
        for (let h = 0; h < arrJiShu2.length; h++) {
          jishuChild1.push(parseInt(arrJiShu2[h]) % 10)
          jishuChild2.push(parseInt(arrJiShu2[h]) / 10)
        }
        let sumJiShu = 0 // 奇数位*2 < 9 的数组之和
        let sumOuShu = 0 // 偶数位数组之和
        let sumJiShuChild1 = 0 // 奇数位*2 >9 的分割之后的数组个位数之和
        let sumJiShuChild2 = 0 // 奇数位*2 >9 的分割之后的数组十位数之和
        let sumTotal = 0

        for (let m = 0; m < arrJiShu.length; m++) {
          sumJiShu = sumJiShu + parseInt(arrJiShu[m])
        }
        for (let n = 0; n < arrOuShu.length; n++) {
          sumOuShu = sumOuShu + parseInt(arrOuShu[n])
        }
        for (let p = 0; p < jishuChild1.length; p++) {
          sumJiShuChild1 = sumJiShuChild1 + parseInt(jishuChild1[p])
          sumJiShuChild2 = sumJiShuChild2 + parseInt(jishuChild2[p])
        }
        // 计算总和
        sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2)
        // 计算Luhm值
        let k = parseInt(sumTotal) % 10 === 0 ? 10 : parseInt(sumTotal) % 10
        let luhm = 10 - k
        if (Number(lastNum) === luhm && lastNum.length !== 0 && value.length > 11) {
          callback()
        } else {
          return callback(new Error('请输入正确的银行卡号'))
        }
      }
    }
  }
}
