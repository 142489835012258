var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "global-layout-header-aside-group",
      class: { grayMode: _vm.grayActive },
      style: _vm.styleLayoutMainGroup,
    },
    [
      _c("div", { staticClass: "global-layout-header-aside-mask" }),
      _c(
        "div",
        {
          staticClass: "global-layout-header-aside-content",
          attrs: { flex: "dir:top" },
        },
        [
          _c(
            "div",
            {
              staticClass: "global-theme-header",
              style: {
                opacity: this.searchActive ? 0.5 : 1,
              },
              attrs: { "flex-box": "0", flex: "" },
            },
            [
              _c(
                "div",
                { staticClass: "logo-wrap" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "logo-group",
                      attrs: { to: "/index", "flex-box": "0" },
                    },
                    [_c("img", { attrs: { src: _vm.logo } })]
                  ),
                  _c("span", { staticClass: "txt" }, [
                    _vm._v(_vm._s(_vm.sysName)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "global-header-right",
                  attrs: { "flex-box": "0" },
                },
                [_c("global-header-fullscreen"), _c("global-header-user")],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "global-theme-container",
              attrs: { "flex-box": "1", flex: "" },
            },
            [
              _c(
                "div",
                {
                  ref: "aside",
                  staticClass: "global-theme-container-aside",
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth,
                    opacity: this.searchActive ? 0.5 : 1,
                  },
                  attrs: { "flex-box": "0" },
                },
                [_c("global-menu-side")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "global-theme-container-main",
                  attrs: { "flex-box": "1", flex: "" },
                },
                [
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "global-theme-container-main-layer",
                            attrs: { flex: "" },
                          },
                          [
                            _c("global-panel-search", {
                              ref: "panelSearch",
                              on: { close: _vm.searchPanelClose },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    !_vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "global-theme-container-main-layer",
                            attrs: { flex: "dir:top" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "global-theme-container-main-header",
                                attrs: { "flex-box": "0" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "toggle-aside-btn",
                                    attrs: { "flex-box": "0" },
                                    on: { click: _vm.handleToggleAside },
                                  },
                                  [
                                    _c("global-icon", {
                                      attrs: { name: "bars" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("global-tabs"),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "global-theme-container-main-body",
                                attrs: { "flex-box": "1" },
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: _vm.transitionActive
                                        ? "fade-transverse"
                                        : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "keep-alive",
                                      { attrs: { include: _vm.keepAlive } },
                                      [
                                        _c(
                                          "globalMainBody",
                                          [_c("router-view")],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }