import _ from 'lodash'
import store from '@/store'
import { sysParamValueBykey } from '@api'
import validate from './validate'
import formatMoney from './formatMoney'

import dayjs from 'dayjs'

export default {
  install(Vue, options) {
    // 获取全局的用户id
    Object.defineProperty(Vue.prototype, '$userId', {
      get() {
        return _.get(store, 'state.user.info.userId', '')
      }
    })

    // 是否需要认证
    Object.defineProperty(Vue.prototype, '$toAuth', {
      get() {
        return _.get(store, 'state.user.info.toAuth', '')
      }
    })

    // 获取全局的用户身份id
    Object.defineProperty(Vue.prototype, '$accountId', {
      get() {
        return _.get(store, 'state.user.info.accountId', '')
      }
    })

    // 身份类型
    Object.defineProperty(Vue.prototype, '$accountType', {
      get() {
        return _.get(store, 'state.user.info.accountType', '')
      }
    })

    // 判断左侧菜单伸缩状态
    Object.defineProperty(Vue.prototype, '$asideCollapse', {
      get() {
        return _.get(store, 'state.menu.asideCollapse', false)
      }
    })

    // 定义全部权限列表
    Object.defineProperty(Vue.prototype, '$permissionList', {
      get() {
        return _.get(store, 'state.account.permission', [])
      }
    })

    // 检验权限
    Vue.prototype.$checkAuth = code => {
      const permissionList = _.get(store, 'state.account.permission', [])
      const currItem = _.find(permissionList, { resourceCode: code })
      return _.hasIn(currItem, 'id')
    }

    // 页面没有数据自动跳转上一页数据
    Vue.prototype.$redirectPrevPage = (isRedirect, page, totalPage, self) => {
      if (isRedirect && page > totalPage && totalPage > 1) {
        self.$router.replace({
          name: _.get(self.$route, 'name', ''),
          query: {
            ..._.get(self.$route, 'query', {}),
            page: totalPage || 1
          }
        })
      }
    }

    Vue.prototype.$currKeyValue = {}

    // 全局获取根据key获取参数值value
    Vue.prototype.$getValueByKey = key => {
      if (!Vue.prototype.$currKeyValue[`${key}`]) {
        sysParamValueBykey(key).then(res => {
          Vue.prototype.$currKeyValue[`${key}`] = res
        })
      }

      return Vue.prototype.$currKeyValue[`${key}`]
    }

    Vue.prototype.$formatTime = (timestamp, format = 'YYYY-MM-DD HH:mm:ss') => {
      if (_.get(timestamp, 'length', 0) === 13) {
        return dayjs(parseInt(timestamp)).format(format)
      }

      const date = timestamp && timestamp != '/' ? new Date(timestamp) : ''
      return date ? dayjs(date).format(format) : '/'
    }

    Vue.prototype.$getJsonData = (obj, string, defaultData = '') => {
      return _.get(obj, `${string}`, defaultData)
    }

    Vue.use(validate)
    Vue.use(formatMoney)
  }
}
