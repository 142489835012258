import Vue from 'vue'

// 添加
const areaSave = data => {
  return Vue.$post('area/save', data)
}

// 修改
const areaUpdate = data => {
  return Vue.$put('area/update', data)
}

// 根据ID查询单个
const areaGet = id => {
  return Vue.$get(`area/get/${id}`)
}

// 删除单个
const areaDelete = id => {
  return Vue.$delete(`area/delete/${id}`)
}

// 批量删除
const areaDeleteBatch = data => {
  return Vue.$delete('area/deleteBatch', data)
}

// 分页查询
const areaPage = data => {
  return Vue.$get('area/page', data)
}

// 通过对象查询列表
const areaList = data => {
  return Vue.$get('area/list', data)
}

export { areaSave, areaUpdate, areaGet, areaDelete, areaPage, areaList, areaDeleteBatch }
