import _ from 'lodash'
import util from '@/libs/util'
import { sysParamValueBykey } from '@api'
export default {
  name: 'global-excel',
  props: {
    action: {
      type: String,
      required: true
    },
    mouldKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      percent: 0,
      fileData: {},
      uploadResult: [],
      loadingBtn: false,
      resultList: []
    }
  },

  computed: {
    uploadHeader() {
      return {
        Token: util.cookies.get('token'),
        userId: _.get(this.$store, 'state.user.info.userId', '')
      }
    }
  },

  methods: {
    openDialog() {
      this.resultList = []
      this.dialogVisible = true
    },
    getEmpExcel() {
      this.loadingBtn = true
      sysParamValueBykey(this.mouldKey)
        .then(res => {
          this.loadingBtn = false
          window.location = res
        })
        .catch(err => {
          this.loadingBtn = false
          console.log(err)
        })
    },

    handleSuccess(res) {
      if (res.code === 200) {
        const msg = _.get(res, 'data.msg', '')
        if (msg) {
          this.$message.error(msg)
        } else {
          const failList = _.map(_.get(res, 'data.failList', []), item => {
            return {
              ...item,
              success: 'red'
            }
          })
          this.resultList = _.concat(this.resultList, failList)
          this.$emit('update')
          this.$message.success(`上传成功`)
        }
      } else {
        this.$message.error(res.message)
      }
    },

    beforeUpload(file) {
      // const isImg = file.type.indexOf('image') > -1
      // const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isImg) {
      //   this.$message.error('您上传的图片格式有误!')
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 2MB!')
      // }
      return true // isImg && isLt2M
    }
  }
}
