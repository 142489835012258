import Vue from 'vue'

// 添加
const mediateSave = data => {
  return Vue.$post('mediate/save', data)
}

// 修改
const mediateUpdate = data => {
  return Vue.$put('mediate/update', data)
}

// 根据ID查询单个
const mediateGet = id => {
  return Vue.$get(`mediate/get/${id}`)
}

// 删除单个
const mediateDelete = id => {
  return Vue.$delete(`mediate/delete/${id}`)
}

// 批量删除
const mediateDeleteBatch = data => {
  return Vue.$delete('mediate/deleteBatch', data)
}

// 分页查询
const mediatePage = data => {
  return Vue.$get('mediate/page', data)
}

// 通过对象查询列表
const mediateList = data => {
  return Vue.$get('mediate/list', data)
}

// 企业审批
const mediateCompanyAudit = data => {
  return Vue.$put('mediate/companyAudit', data)
}

// 企业审批
const mediateLawyerAudit = data => {
  return Vue.$put('mediate/lawyerAudit', data)
}
export {
  mediateSave,
  mediateUpdate,
  mediateGet,
  mediateDelete,
  mediatePage,
  mediateList,
  mediateDeleteBatch,
  mediateCompanyAudit,
  mediateLawyerAudit
}
