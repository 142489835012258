import Vue from 'vue'

// 添加
const agreementTemplateSave = data => {
  return Vue.$post('agreementTemplate/save', data)
}

// 修改
const agreementTemplateUpdate = data => {
  return Vue.$put('agreementTemplate/update', data)
}

// 根据ID查询单个
const agreementTemplateGet = id => {
  return Vue.$get(`agreementTemplate/get/${id}`)
}

// 删除单个
const agreementTemplateDelete = id => {
  return Vue.$delete(`agreementTemplate/delete/${id}`)
}

// 批量删除
const agreementTemplateDeleteBatch = data => {
  return Vue.$delete('agreementTemplate/deleteBatch', data)
}

// 分页查询
const agreementTemplatePage = data => {
  return Vue.$get('agreementTemplate/page', data)
}

// 通过对象查询列表
const agreementTemplateList = data => {
  return Vue.$get('agreementTemplate/list', data)
}

const agreementTemplateUpdateEnable = data => {
  return Vue.$put('agreementTemplate/updateEnable', data)
}

export {
  agreementTemplateSave,
  agreementTemplateUpdate,
  agreementTemplateGet,
  agreementTemplateDelete,
  agreementTemplatePage,
  agreementTemplateList,
  agreementTemplateDeleteBatch,
  agreementTemplateUpdateEnable
}
