<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import util from '@/libs/util'
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle',
    browserIcon() {
      this.setHeadIco()
    }
  },
  created() {
    this.i18nHandle(this.$i18n.locale)
  },
  mounted() {
    this.setHeadIco()
  },
  computed: {
    browserIcon() {
      return _.get(this.$store, 'state.site.siteInfo.browserIcon', '')
    }
  },
  methods: {
    i18nHandle(val, oldVal) {
      util.cookies.set('lang', val)
      document.querySelector('html').setAttribute('lang', val)
    },
    setHeadIco() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.browserIcon) {
            document.querySelector("link[rel*='icon']").setAttribute('href', this.browserIcon)
          }
        }, 500)
      })
    }
  }
}
</script>
