var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "com_no_auth-page" },
    [
      _c("global-icon-svg", { attrs: { name: "global-noauth" } }),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }