import Vue from 'vue'

// 添加
const repaymentAgreementSave = data => {
  return Vue.$post('repaymentAgreement/save', data)
}

// 修改
const repaymentAgreementUpdate = data => {
  return Vue.$put('repaymentAgreement/update', data)
}

// 签署
const repaymentAgreementSignature = data => {
  return Vue.$put('repaymentAgreement/signature', data)
}

// 根据ID查询单个
const repaymentAgreementGet = id => {
  return Vue.$get(`repaymentAgreement/get/${id}`)
}

// 删除单个
const repaymentAgreementDelete = id => {
  return Vue.$delete(`repaymentAgreement/delete/${id}`)
}

// 批量删除
const repaymentAgreementDeleteBatch = data => {
  return Vue.$delete('repaymentAgreement/deleteBatch', data)
}

// 分页查询
const repaymentAgreementPage = data => {
  return Vue.$get('repaymentAgreement/page', data)
}

// 通过对象查询列表
const repaymentAgreementList = data => {
  return Vue.$get('repaymentAgreement/list', data)
}

// 获取验证码
const repaymentAgreementGetSmsCode = data => {
  return Vue.$get('repaymentAgreement/getSmsCode', data)
}

export {
  repaymentAgreementSave,
  repaymentAgreementUpdate,
  repaymentAgreementSignature,
  repaymentAgreementGet,
  repaymentAgreementDelete,
  repaymentAgreementPage,
  repaymentAgreementList,
  repaymentAgreementDeleteBatch,
  repaymentAgreementGetSmsCode
}
