// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'

Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.productionTip = false

// 菜单和路由设置
import router from './router'

// 核心插件
import globalAdmin from '@/plugin/global'

// store
import store from '@/store/index'
import menuHeader from '@/menu/header'
import { frameInRoutes } from '@/router/routes'

// 核心插件
Vue.use(globalAdmin)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('page/init', frameInRoutes)
    // 设置顶栏菜单
    this.$store.commit('menu/headerSet', menuHeader)
    // 初始化菜单搜索功能
    this.$store.commit('search/init', menuHeader)
  },
  mounted() {
    // 展示系统信息
    this.$store.commit('releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('account/load')
    // 获取并记录用户 UA
    this.$store.commit('ua/get')
    // 初始化全屏监听
    this.$store.dispatch('fullscreen/listen')
  }
}).$mount('#app')
