var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-wrap" },
    [
      _c("el-input", {
        attrs: { placeholder: _vm.placeholder, clearable: "" },
        on: { clear: _vm.onSearch },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onSearch.apply(null, arguments)
          },
        },
        model: {
          value: _vm.keyword,
          callback: function ($$v) {
            _vm.keyword = $$v
          },
          expression: "keyword",
        },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary", plain: "", icon: "el-icon-search" },
          on: { click: _vm.onSearch },
        },
        [_vm._v("搜索")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }