import _ from 'lodash'
import util from '@/libs/util'

export default {
  props: {
    prop: {
      type: String,
      required: true
    },
    source: {
      type: String
    },
    fileId: {
      type: String
    },
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '100px'
    },
    size: {
      type: String,
      default: '100px'
    },
    className: {
      type: String,
      default: ''
    },
    uploadType: {
      type: String,
      default: 'image'
    },
    isList: {
      type: Boolean,
      default: false
    },
    sourceList: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cloneSourceList: [],
      fileList: [],
      fileUrl: '',
      id: '',
      dialogfileUrl: '',
      dialogVisible: false
    }
  },

  computed: {
    uploadHeader() {
      return {
        Token: util.cookies.get('token'),
        userId: _.get(this.$store, 'state.user.info.userId', '')
      }
    },

    iconName() {
      const iconNames = {
        license: 'global-business',
        frontCard: 'global-front-card',
        backCard: 'global-back-card'
      }

      return iconNames[this.className]
    },

    fileScurceList() {
      return _.map(this.fileList, item => {
        return {
          url: item
        }
      })
    }
  },

  mounted() {
    this.init()
  },

  watch: {
    source() {
      this.init()
    },
    sourceList() {
      this.init()
    }
  },

  methods: {
    init() {
      this.fileUrl = this.source
      this.id = this.fileId
      this.fileList = _.clone(this.sourceList)
    },

    handleSuccess(res) {
      if (_.get(res, 'code') === 200) {
        const imgObj = _.get(res, 'data', {}) || {}
        this.fileUrl = _.get(imgObj, 'fileUrl', '')
        this.id = _.get(imgObj, 'fileId', '')
        this.$emit('success', {
          prop: this.prop,
          value: imgObj
        })
      } else {
        this.$message.error(_.get(res, 'message', '上传出错！'))
      }
    },

    beforeUpload(file) {
      // const isImg = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isImg) {
      //   this.$message.error('您上传的图片格式有误!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!')
      }
      // return isImg && isLt2M
      return isLt2M
    },

    // 移除当前上传的图片
    removeImage() {
      this.fileUrl = ''
      this.fileId = ''
      this.$emit('success', {
        prop: this.prop,
        value: {}
      })
    },

    listSuccess(res) {
      if (_.get(res, 'code') === 200) {
        const fileUrl = _.get(res, 'data.fileUrl', '') || ''
        this.fileList = _.concat(this.fileList, fileUrl)
        this.$emit('success', {
          prop: this.prop,
          value: this.fileList
        })
      } else {
        this.$message.error(_.get(res, 'message', '图片上传出错！'))
      }
    },

    handleRemove(file) {
      this.$emit('remove', file.url)
    },

    handlePictureCardPreview(file) {
      this.dialogfileUrl = file.url
      this.dialogVisible = true
    }
  }
}
